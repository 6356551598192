import React, { useState, useEffect } from "react";

import axios from "axios";

const Gateway = () => {
  const [RazorPayout, setRazorpayout] = useState(true);
  const [KvmIsActive, setKvmIsActive] = useState(true);
  const [RazorpayAuto, setRazorpayAuto] = useState(false);
  const [RazorPayKey, setRazorpayKey] = useState();
  const [RazorPaySecretKey, setRazorpaysecretKey] = useState();
  const [AccountName, setAccountName] = useState();
  const [decentroPayout, setdecentropayout] = useState(true);
  const [decentroDeposit, setdecentroDeposit] = useState(true);
  const [decentroAuto, setdecentroAuto] = useState(false);
  const [IndiPayIsActive, setIndiPayIsActive] = useState(false);
  const [IndiPayPayoutIsActive, setIndiPayPayoutIsActive] = useState(false);
  const [settingId, setSettingId] = useState("");
  const [kvmActiveGateway, setKvmActiveGateway] = useState(1);
  

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  useEffect(() => {
    const data = axios.get(baseUrl + "gatewaysettings/data", {}).then((res) => {
      console.log(res.data);
      setSettingId((res.data._id) ? res.data._id : '');
      setRazorpayout(res.data.RazorPayout || false);
      setKvmIsActive(res.data.KvmIsActive || false);
      setRazorpayAuto(res.data.RazorpayAuto || false);
      setdecentropayout(res.data.decentroPayout || false);
      setdecentroDeposit(res.data.decentroDeposit || false);
      setdecentroAuto(res.data.decentroAuto || false);
      setIndiPayIsActive(res.data.IndiPayIsActive || false);
      setIndiPayPayoutIsActive(res.data.IndiPayPayoutIsActive || false);
      setRazorpayKey(res.data.RazorPayKey || '');
      setRazorpaysecretKey(res.data.RazorPaySecretKey || '');
      setAccountName(res.data.AccountName || '');
      setKvmActiveGateway(res.data.kvmActiveGateway || 1);
    });
  }, []);

  const handleSubmit1 = async (e) => {
    e.preventDefault();

    const response = await axios.post(
      baseUrl + `gatewaysettings`,
      {
        settingId,
        RazorPayout,
        KvmIsActive,
        RazorpayAuto,
        decentroPayout,
        decentroDeposit,
        decentroAuto,
        IndiPayIsActive,
        IndiPayPayoutIsActive,
        RazorPayKey,
        RazorPaySecretKey,
        AccountName,
        kvmActiveGateway
      }
    );
    console.log(response.data.status);
    if (response.data.status === 'success') {
      alert("Settings submitted successfully");
    } else {
      alert("Settings Not Submitted");
    }
  };

  return (
    <>
      <h4 className="text-uppercase font-weight-bold my-3 text-white">
        Payment Gateway Settings
      </h4>

      <h5 className="text-uppercase font-weight-bold my-3 text-white">KVM</h5>

      <form
        action="gatewaysettings"
        className="form"
        onSubmit={handleSubmit1}
        method="patch"
        enctype="multipart/form-date"
      >
        <div className="form-row">
        <div className="form-group col-md-4">
            <label htmlFor="buttonrazdep" className=" my-1">
              KVM Gateway
            </label>
            <select
              className="form-control "
              name="kvmActiveGateway"
              id="kvmActiveGateway"
              value={kvmActiveGateway}
              onChange={(e) => setKvmActiveGateway(e.target.value)}
            >
              <option value="1">KVM - Shankar</option>
              <option value="2">KVM - Nathu</option>
              <option value="3">KVM - Damodar</option>
            </select>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="buttonrazdep" className=" my-1">
              KVM Deposit - Add Money
            </label>
            <select
              className="form-control "
              name="KvmIsActive"
              id="KvmIsActive"
              value={KvmIsActive}
              onChange={(e) => setKvmIsActive(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
          
          </div>
          <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="buttonrazpay" className="my-1">
              KVM Payout - Withdrawal Money
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={RazorPayout}
              onChange={(e) => setRazorpayout(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="buttonrazauto" className=" my-1">
              KVM Auto
            </label>
            <select
              className="form-control"
              name=""
              id=""
              value={RazorpayAuto}
              onChange={(e) => setRazorpayAuto(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
        </div>
        <h5 className="text-uppercase font-weight-bold my-3 text-white">IndiPay</h5>
        <div className="form-row">
          <div className="form-group col-md-3">
            <label htmlFor="IndiPayIsActive">IndiPay Live</label>
            <select
              id="IndiPayIsActive"
              name="IndiPayIsActive"
              className="form-control"
              value={IndiPayIsActive ? true : false}
              onChange={(e) => setIndiPayIsActive(e.target.value)}
            >
              <option value={true}>Enable</option>
              <option value={false}>Disable</option>
            </select>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="IndiPayPayoutIsActive">IndiPay Payout Live</label>
            <select
              id="IndiPayPayoutIsActive"
              name="IndiPayPayoutIsActive"
              className="form-control"
              value={IndiPayPayoutIsActive ? true : false}
              onChange={(e) => setIndiPayPayoutIsActive(e.target.value)}
            >
              <option value={true}>Enable</option>
              <option value={false}>Disable</option>
            </select>
          </div>
        </div>

        {/* <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="RazorpayKey">RazorPay Key</label>
            <input
              className="form-control"
              type="text"
              value={RazorPayKey}
              onChange={(e) => setRazorpayKey(e.target.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="RazorpaysecretKey">RazorPay SecretKey</label>
            <input
              className="form-control"
              type="text"
              value={RazorPaySecretKey}
              onChange={(e) => setRazorpaysecretKey(e.target.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="AccountName">Account Name</label>
            <input
              className="form-control"
              type="text"
              value={AccountName}
              onChange={(e) => setAccountName(e.target.value)}
            />
          </div>
        </div> */}

        {/* <div className="form-row">
          <div className="form-group col-md-4">
            <h5 className="text-uppercase font-weight-bold my-3">Decentro</h5>

            <label htmlFor="buttondecpay" className="col-2 my-1">
              Decentro payout
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={decentroPayout}
              onChange={(e) => setdecentropayout(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="buttondecdep" className="col-2 my-1">
              Decentro Deposit
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={decentroDeposit}
              onChange={(e) => setdecentroDeposit(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>

            <label htmlFor="buttondecdep" className="col-2 my-1">
              Decentro Auto
            </label>
            <select
              className="form-control "
              name=""
              id=""
              value={decentroAuto}
              onChange={(e) => setdecentroAuto(e.target.value)}
            >
              <option value="true">Enable</option>
              <option value="false">Disable</option>
            </select>
          </div>
        </div> */}
        <div>&nbsp;</div>
        <div>&nbsp;</div> 
        <div className="form-row">
          <div className="form-group col-md-4">
            <button type="submit" className="btn btn-danger">
              Submit
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default Gateway;
