import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import Gateway from "./Gateway";


export const Sitesettings = () => {

  const [WebTitle, setWebTitle] = useState("");
  const [WebsiteName, setWebName] = useState("");
  const [CompanyName, setCompanyName] = useState("");
  const [CompanyAddress, setCompanyAddress] = useState("");
  const [CompanyMobile, setCompanyMobile] = useState("");
  const [CompanyEmail, setCompanyEmail] = useState("");
  const [CompanyWebsite, setCompanyWebsite] = useState("");

  const [homeMsg, setHomeMsg] = useState("");
  const [depositMsg, setDepositMsg] = useState("");
  const [withdrawMsg, setWithdrawMsg] = useState("");
  const [gameMsg, setGameMsg] = useState("");

  const [Logo, setLogo] = useState("");
  const [SmallLogo, setSmallLogo] = useState("");

  const [LandingImage1, setLandingImage1] = useState("");
  const [LandingImage2, setLandingImage2] = useState("");
  const [LandingImage3, setLandingImage3] = useState("");
  const [LandingImage4, setLandingImage4] = useState("");

  const [isLandingImage1, issetLandingImage1] = useState(true);
  const [isLandingImage2, issetLandingImage2] = useState(true);
  const [isLandingImage3, issetLandingImage3] = useState(true);
  const [isLandingImage4, issetLandingImage4] = useState(true);

  // TODO : mk
  const [qrCode1, setQrCode1] = useState(null);
  const [qrCode2, setQrCode2] = useState(null);
  const [qrCode3, setQrCode3] = useState(null);
  const [qrCode4, setQrCode4] = useState(null);


  // const [version, setVersion] = useState("");

  const [settingId, setSettingId] = useState("");

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  // TODO: mk work
  const [formDataNew, setFormData] = useState({
    version: '',
    whatsup_1: '',
    whatsup_2: '',
    whatsup_3: '',
    commission_fee: '',
    referral_amount: '',
    minBidAmount: '',
    maxBidAmount: '',
    minWithdrawalAmount: '',
    maxWithdrawalAmount: '',
    minDepositAmount: '',
    maxDepositAmount: '',
    isLive: false,

    qrCode1: '',
    qrCode2: '',
    qrCode3: '',
    qrCode4: '',

    isQrCode1: false,
    isQrCode2: false,
    isQrCode3: false,
    isQrCode4: false,
    webNotification: ''

  });

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    console.log("-type", type)
    if (type === "file") {
      const file = e.target.files[0];
      if (name === "QrCode1") setQrCode1(file);
      else if (name === "QrCode2") setQrCode2(file);
      else if (name === "QrCode3") setQrCode3(file);
      else if (name === "QrCode4") setQrCode4(file);
    } else {
      setFormData({ ...formDataNew, [name]: value });
    }
  };

  useEffect(() => {
    axios.get(baseUrl + "settings/data", {}).then((res) => {
      console.log(res.data);
      setSettingId((res.data._id) ? res.data._id : '');
      setWebTitle(res.data.WebTitle)
      setWebName(res.data.WebsiteName);
      setCompanyName(res.data.CompanyName);
      setCompanyAddress(res.data.CompanyAddress);
      setCompanyMobile(res.data.CompanyMobile);
      setCompanyEmail(res.data.CompanyEmail);
      setCompanyWebsite(res.data.CompanyWebsite);

      setHomeMsg(res.data.homeMsg);
      setGameMsg(res.data.gameMsg);
      setWithdrawMsg(res.data.withdrawMsg);
      setDepositMsg(res.data.depositMsg);


      setLogo(res.data.Logo);
      setSmallLogo(res.data.SmallLogo);
      setLandingImage1(res.data.LandingImage1);
      setLandingImage2(res.data.LandingImage2);
      setLandingImage3(res.data.LandingImage3);
      setLandingImage4(res.data.LandingImage4);
      issetLandingImage1(res.data.isLandingImage1);
      issetLandingImage2(res.data.isLandingImage2);
      issetLandingImage3(res.data.isLandingImage3);
      issetLandingImage4(res.data.isLandingImage4);

      setQrCode1(res.data.qrCode1);
      setQrCode2(res.data.qrCode2);
      setQrCode3(res.data.qrCode3);
      setQrCode4(res.data.qrCode4);
      // setVersion(res.data.version)

      setFormData({
        version: res.data.version || '',
        whatsup_1: res.data.whatsup_1 || '',
        whatsup_2: res.data.whatsup_2 || '',
        whatsup_3: res.data.whatsup_3 || '',
        commission_fee: res.data.commission_fee || '',
        referral_amount: res.data.referral_amount || '',
        minBidAmount: res.data.minBidAmount || '',
        maxBidAmount: res.data.maxBidAmount || '',
        minWithdrawalAmount: res.data.minWithdrawalAmount || '',
        maxWithdrawalAmount: res.data.maxWithdrawalAmount || '',
        minDepositAmount: res.data.minDepositAmount || '',
        maxDepositAmount: res.data.maxDepositAmount || '',
        isLive: res.data.isLive || false,
        isQrCode1: res.data.isQrCode1 || false,
        isQrCode2: res.data.isQrCode2 || false,
        isQrCode3: res.data.isQrCode3 || false,
        isQrCode4: res.data.isQrCode4 || false,
        webNotification: res.data.webNotification || ''
      });

    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("settingId", settingId);
    formData.append("WebTitle", WebTitle);
    formData.append("WebsiteName", WebsiteName);
    formData.append("CompanyName", CompanyName);
    formData.append("CompanyAddress", CompanyAddress);
    formData.append("CompanyMobile", CompanyMobile);
    formData.append("CompanyEmail", CompanyEmail);
    formData.append("CompanyWebsite", CompanyWebsite);

    formData.append("homeMsg", homeMsg);
    formData.append("depositMsg", depositMsg);
    formData.append("withdrawMsg", withdrawMsg);
    formData.append("gameMsg", gameMsg);

    formData.append("Logo", Logo);
    formData.append("SmallLogo", SmallLogo);
    formData.append("LandingImage1", LandingImage1);
    formData.append("LandingImage2", LandingImage2);
    formData.append("LandingImage3", LandingImage3);
    formData.append("LandingImage4", LandingImage4);
    formData.append("isLandingImage1", isLandingImage1);
    formData.append("isLandingImage2", isLandingImage2);
    formData.append("isLandingImage3", isLandingImage3);
    formData.append("isLandingImage4", isLandingImage4);
    // formData.append("version", version);
    formData.append("version", formDataNew.version);
    formData.append("whatsup_1", formDataNew.whatsup_1);
    formData.append("whatsup_2", formDataNew.whatsup_2);
    formData.append("whatsup_3", formDataNew.whatsup_3);
    formData.append("commission_fee", formDataNew.commission_fee);
    formData.append("referral_amount", formDataNew.referral_amount);
    formData.append("minBidAmount", formDataNew.minBidAmount);
    formData.append("maxBidAmount", formDataNew.maxBidAmount);
    formData.append("minWithdrawalAmount", formDataNew.minWithdrawalAmount);
    formData.append("maxWithdrawalAmount", formDataNew.maxWithdrawalAmount);
    formData.append("minDepositAmount", formDataNew.minDepositAmount);
    formData.append("maxDepositAmount", formDataNew.maxDepositAmount);
    formData.append("isLive", formDataNew.isLive);
    formData.append("webNotification", formDataNew.webNotification);


    formData.append("qrCode1", qrCode1);
    formData.append("isQrCode1", formDataNew.isQrCode1);
    formData.append("qrCode2", qrCode2);
    formData.append("isQrCode2", formDataNew.isQrCode2);
    formData.append("qrCode3", qrCode3);
    formData.append("isQrCode3", formDataNew.isQrCode3);
    formData.append("qrCode4", qrCode4);
    formData.append("isQrCode4", formDataNew.isQrCode4);

    const response = await axios.post(
      baseUrl + `settings`,
      formData
    );
    console.log(response.data.status);
    if (response.data.status === 'success') {
      alert("Settings submitted successfully");
    } else {
      alert("Settings Not Submitted");
    }
  };


  useEffect(() => {
    const Logo1 = document.getElementById("Logo");
    const Logo2 = document.getElementById("SmallLogo");
    const LandingImage1 = document.getElementById("LandingImage1");
    const LandingImage2 = document.getElementById("LandingImage2");
    const LandingImage3 = document.getElementById("LandingImage3");
    const LandingImage4 = document.getElementById("LandingImage4");

    Logo1.onchange = (e) => {
      const [file] = Logo1.files;
      setLogo(file);
    };
    Logo2.onchange = (e) => {
      const [file] = Logo2.files;
      setSmallLogo(file);
    };
    LandingImage1.onchange = (e) => {
      const [file] = LandingImage1.files;
      setLandingImage1(file);
    };
    LandingImage2.onchange = (e) => {
      const [file] = LandingImage2.files;
      setLandingImage2(file);
    };
    LandingImage3.onchange = (e) => {
      const [file] = LandingImage3.files;
      setLandingImage3(file);
    };
    LandingImage4.onchange = (e) => {
      const [file] = LandingImage4.files;
      setLandingImage4(file);
    };
  }, []);

  return (
    <>
      <h3 className="text-uppercase font-weight-bold my-3 text-white">Website Settings</h3>

      <h4 className="text-uppercase font-weight-bold my-3 text-light" >UI Settings</h4>
      <form
        onSubmit={handleSubmit}
        method="patch"
        encType="multipart/form-data"
        style={{ backgroundColor: "rgba(0, 27, 11, 0.734)" }}
      >
        <div className="form-row">
          <div className="form-group col-md-4">

            <label htmlFor="WebsiteName">Website Title</label>
            <input
              className="form-control"
              type="text"
              value={WebTitle}
              onChange={(e) => setWebTitle(e.target.value)}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">

            <label htmlFor="WebsiteName">Website Name</label>
            <input
              className="form-control"
              type="text"
              value={WebsiteName}
              onChange={(e) => setWebName(e.target.value)}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Commpany Name</label>
            <input
              className="form-control"
              type="text"
              value={CompanyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Commpany Address</label>
            <input
              className="form-control"
              type="text"
              value={CompanyAddress}
              onChange={(e) => setCompanyAddress(e.target.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Commpany Mobile</label>
            <input
              className="form-control"
              type="text"
              value={CompanyMobile}
              onChange={(e) => setCompanyMobile(e.target.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="WebsiteEmail">Commpany Email</label>
            <input
              className="form-control"
              type="text"
              value={CompanyEmail}
              onChange={(e) => setCompanyEmail(e.target.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="WebsiteWebsite">Commpany Website</label>
            <input
              className="form-control"
              type="text"
              value={CompanyWebsite}
              onChange={(e) => setCompanyWebsite(e.target.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="WebsiteWebsite">Home Page Message</label>
            <input
              className="form-control"
              type="text"
              value={homeMsg}
              onChange={(e) => setHomeMsg(e.target.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="WebsiteWebsite">Deposit Page Message</label>
            <input
              className="form-control"
              type="text"
              value={depositMsg}
              onChange={(e) => setDepositMsg(e.target.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="WebsiteWebsite">Withdraw Page Message</label>
            <input
              className="form-control"
              type="text"
              value={withdrawMsg}
              onChange={(e) => setWithdrawMsg(e.target.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="WebsiteWebsite">Game Page Message</label>
            <input
              className="form-control"
              type="text"
              value={gameMsg}
              onChange={(e) => setGameMsg(e.target.value)}
            />
          </div>

        </div>
        <div className="form-row">
          <div className="form-group col-md-12">
            <label htmlFor="WebsiteWebsite">Notification</label>

            <input
              id="webNotification"
              name="webNotification"
              className="form-control"
              type="text"
              value={formDataNew.webNotification}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">

            <label htmlFor="WebsiteName">Right Logo</label>
            <input className="form-control" type="file" name="Logo" id="Logo" />
          </div>
          <div className="form-group col-md-2">
            {Logo && <img src={process.env.REACT_APP_BACKEND_LIVE_API + Logo} width={"100px"} />}
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Left Logo</label>
            <input className="form-control" type="file" name="SmallLogo" id="SmallLogo" />
          </div>
          <div className="form-group col-md-2">
            {SmallLogo && <img src={process.env.REACT_APP_BACKEND_LIVE_API + SmallLogo} width={"100px"} />}
          </div>

        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Game image (1) </label>
            <input className="form-control" type="file" name="LandingImage1" id="LandingImage1" />
            <select className="form-control" name="" id="" value={isLandingImage1} onChange={(e) => issetLandingImage1(e.target.value)}>
              <option value="true">on</option>
              <option value="false">off</option>
            </select>
          </div>
          <div className="form-group col-md-2">
            {isLandingImage1 && <img src={process.env.REACT_APP_BACKEND_LIVE_API + LandingImage1} width={"100px"} />}
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Game image (2)</label>
            <input className="form-control" type="file" name="LandingImage2" id="LandingImage2" />
            <select className="form-control" name="" id="" value={isLandingImage2} onChange={(e) => issetLandingImage2(e.target.value)}>
              <option value="true">on</option>
              <option value="false">off</option>
            </select>
          </div>
          <div className="form-group col-md-2">
            {isLandingImage2 && <img src={process.env.REACT_APP_BACKEND_LIVE_API + LandingImage2} width={"100px"} />}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Game image (3)</label>
            <input className="form-control" type="file" name="LandingImage3" id="LandingImage3" />
            <select className="form-control" name="" id="" value={isLandingImage3} onChange={(e) => issetLandingImage3(e.target.value)}>
              <option value="true">on</option>
              <option value="false">off</option>
            </select>
          </div>
          <div className="form-group col-md-2">
            {isLandingImage3 && <img src={process.env.REACT_APP_BACKEND_LIVE_API + LandingImage3} width={"100px"} />}
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Game image (4)</label>
            <input className="form-control" type="file" name="LandingImage4" id="LandingImage4" />
            <select className="form-control" name="" id="" value={isLandingImage4} onChange={(e) => issetLandingImage4(e.target.value)}>
              <option value="true">on</option>
              <option value="false">off</option>
            </select>
          </div>
          <div className="form-group col-md-2">
            {isLandingImage4 && <img src={process.env.REACT_APP_BACKEND_LIVE_API + LandingImage4} width={"100px"} />}
          </div>

        </div>

        {/* TODO: mk work */}
        <div className="form-row">
          <div className="form-group col-md-3">
            <label htmlFor="version">Version</label>
            <input
              id="version"
              name="version"
              className="form-control"
              type="text"
              value={formDataNew.version}
              onChange={handleChange}
            />
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="commission_fee">Commission Fee</label>
            <input
              id="commission_fee"
              name="commission_fee"
              className="form-control"
              type="number"
              value={formDataNew.commission_fee}
              onChange={handleChange}
            />
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="referral_amount">Referral Commission</label>
            <input
              id="referral_amount"
              name="referral_amount"
              className="form-control"
              type="number"
              value={formDataNew.referral_amount}
              onChange={handleChange}
            />
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="whatsup_1">Whatsup: Game Result (Win/Lost)</label>
            <input
              id="whatsup_1"
              name="whatsup_1"
              className="form-control"
              type="number"
              value={formDataNew.whatsup_1}
              onChange={handleChange}
            />
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="whatsup_2">Whatsup: Withdrawal and Deposit</label>
            <input
              id="whatsup_2"
              name="whatsup_2"
              className="form-control"
              type="number"
              value={formDataNew.whatsup_2}
              onChange={handleChange}
            />
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="whatsup_3">Whatsup: Other Problems</label>
            <input
              id="whatsup_3"
              name="whatsup_3"
              className="form-control"
              type="number"
              value={formDataNew.whatsup_3}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Depost Withdrawal Setting - MK  */}
        <div className="form-row">
          <div className="form-group col-md-3">
            <label htmlFor="minBidAmount">Min Bid Amount</label>
            <input
              id="minBidAmount"
              name="minBidAmount"
              className="form-control"
              type="number"
              min={0}
              value={formDataNew.minBidAmount}
              onChange={handleChange}
            />
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="maxBidAmount">Max Bid Amount</label>
            <input
              id="maxBidAmount"
              name="maxBidAmount"
              className="form-control"
              type="number"
              min={0}
              value={formDataNew.maxBidAmount}
              onChange={handleChange}
            />
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="minWithdrawalAmount">Min Withdrawal Amount</label>
            <input
              id="minWithdrawalAmount"
              name="minWithdrawalAmount"
              className="form-control"
              type="number"
              min={0}
              value={formDataNew.minWithdrawalAmount}
              onChange={handleChange}
            />
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="maxWithdrawalAmount">Max Withdrawal Amount</label>
            <input
              id="maxWithdrawalAmount"
              name="maxWithdrawalAmount"
              className="form-control"
              type="number"
              min={0}
              value={formDataNew.maxWithdrawalAmount}
              onChange={handleChange}
            />
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="minDepositAmount">Min Deposit Amount</label>
            <input
              id="minDepositAmount"
              name="minDepositAmount"
              className="form-control"
              type="number"
              min={0}
              value={formDataNew.minDepositAmount}
              onChange={handleChange}
            />
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="maxDepositAmount">Max Deposit Amount</label>
            <input
              id="maxDepositAmount"
              name="maxDepositAmount"
              className="form-control"
              type="number"
              min={0}
              value={formDataNew.maxDepositAmount}
              onChange={handleChange}
            />
          </div>
          {/* <div className="form-group col-md-3">
            <label htmlFor="isLive">Is Live</label>
            <select
              id="isLive"
              name="isLive"
              className="form-control"
              value={formDataNew.isLive ? "Yes" : "No"}
              onChange={(e) => setFormData({
                ...formDataNew,
                isLive: e.target.value === "Yes"
              })}
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div> */}
        </div>


        {/* QR Code For Manual Payment - MK  */}
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="QrCode1">QR Code (1)</label>
            <input
              className="form-control"
              type="file"
              name="QrCode1"
              id="QrCode1"
              onChange={handleChange}
            />

            <select
              className="form-control"
              name="isQrCode1"
              id="isQrCode1"
              value={formDataNew.isQrCode1}
              onChange={handleChange}
            >
              <option value="true">on</option>
              <option value="false">off</option>
            </select>
          </div>
          <div className="form-group col-md-4">
            {qrCode1 && <img src={`http://localhost:9012/${qrCode1}`} width={"100px"} />}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="QrCode2">QR Code (2)</label>
            <input
              className="form-control"
              type="file"
              name="QrCode2"
              id="QrCode2"
              onChange={handleChange}
            />
            <select
              className="form-control"
              name="isQrCode2"
              id="isQrCode2"
              value={formDataNew.isQrCode2}
              onChange={handleChange}
            >
              <option value="true">on</option>
              <option value="false">off</option>
            </select>
          </div>
          <div className="form-group col-md-4">
            {qrCode2 && <img src={`http://localhost:9012/${qrCode2}`} width={"100px"} />}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="QrCode3">QR Code (3)</label>
            <input
              className="form-control"
              type="file"
              name="QrCode3"
              id="QrCode3"
              onChange={handleChange}
            />
            <select
              className="form-control"
              name="isQrCode3"
              id="isQrCode3"
              value={formDataNew.isQrCode3}
              onChange={handleChange}
            >
              <option value="true">on</option>
              <option value="false">off</option>
            </select>
          </div>
          <div className="form-group col-md-4">
            {qrCode3 && <img src={`http://localhost:9012/${qrCode3}`} width={"100px"} />}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="QrCode4">QR Code (4)</label>
            <input
              className="form-control"
              type="file"
              name="QrCode4"
              id="QrCode4"
              onChange={handleChange}
            />
            <select
              className="form-control"
              name="isQrCode4"
              id="isQrCode4"
              value={formDataNew.isQrCode4}
              onChange={handleChange}
            >
              <option value="true">on</option>
              <option value="false">off</option>
            </select>
          </div>
          <div className="form-group col-md-4">
            {qrCode4 && <img src={`http://localhost:9012/${qrCode4}`} width={"100px"} />}
          </div>
        </div>



        <div className="form-row">

          <div className="form-group col-md-4">
            <button type="submit" className="btn btn-danger">Submit</button>

          </div>
        </div>


      </form>
      <Gateway />
    </>
  );
};
